<template>
  <div :class="['cs-popup', `cs-popup--${mode}`]" @click="close">
    <div
      class="cs-popup__inner"
      :class="{
        'cs-popup__inner--pd0': notPadding,
        'cs-popup__inner--txc': textCenter,
      }"
      :style="`max-width: ${maxWidth}`"
      @click.stop
    >
      <SBtn v-if="isVisibleBtnClose" icon="Close" @click="emit('close')" />

      <CSUTitle
        class="cs-mb-12"
        :title="title"
        color="base-text-color-white"
        is-popup
      />

      <CSUParagraph
        class="cs-mb-28"
        color="base-text-color-secondary"
        :description="description"
      />

      <div class="cs-popup__body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { appHeight } from '@/helpers/defaultUtils'
import SBtn from '@/components/base/buttons/SBtn.vue'
import CSUTitle from '@/components/ui/ui-text/CSUTitle.vue'
import CSUParagraph from '@/components/ui/ui-text/CSUParagraph.vue'

const props = defineProps({
  mode: {
    type: String,
    default: 'primary',
  },
  maxWidth: {
    type: String,
    default: '328px',
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  isVisibleBtnClose: {
    type: Boolean,
    default: false,
  },
  disableOnClickOutside: {
    type: Boolean,
    default: false,
  },
  notPadding: {
    type: Boolean,
    default: false,
  },
  textCenter: {
    type: Boolean,
    default: false,
  },
  isFixedClose: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

const scrollPosition = ref<number>(0)

const appHeightTM = () => appHeight('--vh')

const close = () => {
  if (!props.disableOnClickOutside) {
    emit('close')
  }
}

const html = document.querySelector('html') as HTMLElement

onMounted(() => {
  const scrollbarWidth =
    window.innerWidth - window.document.documentElement.clientWidth
  scrollPosition.value = window.pageYOffset
  html.classList.add('locked')
  document.body.style.top = `-${scrollPosition.value}px`
  document.body.style.paddingRight = `${scrollbarWidth}px`
  appHeightTM()
})

onBeforeUnmount(() => {
  html.classList.remove('locked')
  document.body.style.removeProperty('top')
  document.body.style.removeProperty('padding-right')
  window.scrollTo(0, scrollPosition.value)
})
</script>

<style lang="scss" scoped>
.cs-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(4px);
  z-index: 10001;

  &__inner {
    position: relative;
    width: 100%;
    min-width: 320px;
    max-width: 400px;
    overflow-y: auto;
    max-height: 90vh;
    border-radius: 24px 24px 0 0;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: rgba(37, 37, 37, 0.9);
    backdrop-filter: blur(25px);
    padding: 20px;
    margin: 0;

    @media (max-width: 767px) {
      max-height: calc(var(--vh, 1vh) * 100);
    }

    &--pd0 {
      padding: 0;
    }

    &--txc {
      :deep(.cs-t) {
        text-align: center;
      }
      :deep(.cs-p) {
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  :deep(.s-btn) {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
  }

  &__body {
    width: 100%;
    word-break: break-word;
  }

  &--mobile {
    .cs-popup {
      &__body,
      &__inner {
        @media (max-width: 767px) {
          height: 100%;
          overflow: hidden;
        }
      }

      &__inner {
        @media (max-width: 767px) {
          max-width: inherit !important;
          border-radius: 0;
          max-height: calc(var(--vh, 1vh) * 100);
        }
      }
    }
  }

  /* mode */
  &--primary {
    .cs-popup {
      &__body,
      &__inner {
        @media (max-width: 767px) {
          max-width: inherit !important;
          height: initial;
          max-height: calc(var(--vh, 1vh) * 100);
        }
      }

      &__inner {
        @media (max-width: 767px) {
          border-radius: 24px 24px 0 0;
          max-height: initial;
        }
      }
    }
  }
}
</style>
