import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cs-popup__body" }

import { ref, onMounted, onBeforeUnmount } from 'vue'
import { appHeight } from '@/helpers/defaultUtils'
import SBtn from '@/components/base/buttons/SBtn.vue'
import CSUTitle from '@/components/ui/ui-text/CSUTitle.vue'
import CSUParagraph from '@/components/ui/ui-text/CSUParagraph.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CSPopup',
  props: {
  mode: {
    type: String,
    default: 'primary',
  },
  maxWidth: {
    type: String,
    default: '328px',
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  isVisibleBtnClose: {
    type: Boolean,
    default: false,
  },
  disableOnClickOutside: {
    type: Boolean,
    default: false,
  },
  notPadding: {
    type: Boolean,
    default: false,
  },
  textCenter: {
    type: Boolean,
    default: false,
  },
  isFixedClose: {
    type: Boolean,
    default: false,
  },
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit

const scrollPosition = ref<number>(0)

const appHeightTM = () => appHeight('--vh')

const close = () => {
  if (!props.disableOnClickOutside) {
    emit('close')
  }
}

const html = document.querySelector('html') as HTMLElement

onMounted(() => {
  const scrollbarWidth =
    window.innerWidth - window.document.documentElement.clientWidth
  scrollPosition.value = window.pageYOffset
  html.classList.add('locked')
  document.body.style.top = `-${scrollPosition.value}px`
  document.body.style.paddingRight = `${scrollbarWidth}px`
  appHeightTM()
})

onBeforeUnmount(() => {
  html.classList.remove('locked')
  document.body.style.removeProperty('top')
  document.body.style.removeProperty('padding-right')
  window.scrollTo(0, scrollPosition.value)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['cs-popup', `cs-popup--${__props.mode}`]),
    onClick: close
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["cs-popup__inner", {
        'cs-popup__inner--pd0': __props.notPadding,
        'cs-popup__inner--txc': __props.textCenter,
      }]),
      style: _normalizeStyle(`max-width: ${__props.maxWidth}`),
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      (__props.isVisibleBtnClose)
        ? (_openBlock(), _createBlock(SBtn, {
            key: 0,
            icon: "Close",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
          }))
        : _createCommentVNode("", true),
      _createVNode(CSUTitle, {
        class: "cs-mb-12",
        title: __props.title,
        color: "base-text-color-white",
        "is-popup": ""
      }, null, 8, ["title"]),
      _createVNode(CSUParagraph, {
        class: "cs-mb-28",
        color: "base-text-color-secondary",
        description: __props.description
      }, null, 8, ["description"]),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "body")
      ])
    ], 6)
  ], 2))
}
}

})