import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ntb-sc" }
const _hoisted_2 = {
  key: 0,
  class: "ntb-sc__lf"
}
const _hoisted_3 = { class: "ntb-sc__cn cs-w100" }
const _hoisted_4 = {
  key: 0,
  class: "ntb-sc__sbt"
}
const _hoisted_5 = { class: "txt-ovh-1line-clamp" }


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationSuccessContent',
  props: {
  info: {
    type: Object,
    default: null,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_CSPIcons = _resolveComponent("CSPIcons")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.info?.data?.iconName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CSPIcons, {
            "name-icon": __props.info.data.iconName
          }, null, 8, ["name-icon"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (__props.info?.data?.subTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (__props.info?.data?.subIconName)
              ? (_openBlock(), _createBlock(_component_CSPIcons, {
                  key: 0,
                  "name-icon": __props.info.data.subIconName
                }, null, 8, ["name-icon"]))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_5, _toDisplayString(__props.info.data.subTitle), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})