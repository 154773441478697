import Cookies from 'js-cookie'

export const setJWT = (token: string) => {
  Cookies.set('jwt_access_token', token)
}

export const deleteJWT = () => {
  Cookies.remove('jwt_access_token')
}

export const getJWT = () => {
  try {
    return {
      accessToken: Cookies.get('jwt_access_token'),
    }
  } catch (err) {
    return {
      accessToken: null,
    }
  }
}
