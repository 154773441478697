<template>
  <component :is="iconComponent" class="i-icon" />
</template>

<script lang="ts">
import { defineComponent, computed, defineAsyncComponent } from 'vue'

export default defineComponent({
  name: 'CSPIcons',
  props: {
    nameIcon: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const iconComponent = computed(() =>
      defineAsyncComponent(
        () => import(`../../../assets/svg/${props.nameIcon}.vue`),
      ),
    )

    return {
      iconComponent,
    }
  },
})
</script>

<style lang="scss" scoped>
.i-icon {
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  transition: all ease 0.3s;
}
</style>
