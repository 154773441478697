import { FRIENDS_REWARD } from '@/helpers/const'

export default {
  main_loading: {
    title: 'Well Payed',
    description: 'Become the Top Earner <br> in Beastville',
    loading: 'Loading',
  },
  profile: {
    settings: {
      title: 'Settings',
      telegram: {
        title: 'Telegram ID',
      },
      lang: {
        title: 'Language',
      },
      support: {
        title: 'Report an Issue',
      },
      deleteAccount: {
        title: 'Delete an account',
      },
      popupDelete: {
        title: 'Are you sure you want to delete your account?',
        description:
          'All your data, including game progress, achievements, and purchases, will be permanently deleted. This action cannot be undone.',
        btnOneTitle: 'Delete Account',
        btnTwoTitle: 'Cancel',
      },
      notification: {
        copyTitle: 'Copied',
      },
    },
  },
  company: {
    firstOffer: {
      title: 'Received an offer in',
      description:
        'Accept the offer at $companyNames or continue your job search',
      bonusTitle: 'Company Stats',
      labelOneText: 'to experience',
      labelTwoText: 'for payment',
      btnTitleOne: 'Accept',
      btnTitleTwo: '',
    },
    secondOffer: {
      title: 'Received an offer in',
      description:
        'Accept the offer at $companyNames or continue your job search',
      bonusTitle: 'Company Stats',
      labelOneText: 'to experience',
      labelTwoText: 'for payment',
      btnTitleOne: 'Accept',
      btnTitleTwo: 'Continue searching',
    },
    list: {
      title: 'Companies',
      subTitle: 'All companies',
      btnTitle: 'Switch company',
    },
  },
  notification: {
    startJobSearch: {
      title: 'Job Search Available',
      btnTitle: 'Start',
    },
    jobSearch: {
      title: 'Chance to find a job',
    },
    friends: {
      title: 'Friend Earnings',
      btnTitle: 'Collect',
    },
  },
  tooltip: {
    'start-click-person': 'Tap on your character <br> to look for a job',
    'start-tsk-person': 'Tap on the character to start the task',
    'start-company': {
      title: 'Congratulations on your first <br>job!',
      description:
        'Now you work for <br> a company that will <br> send you tasks',
      btnTitle: 'Next',
      btnNext: 'start-task',
    },
    'start-task': {
      title: 'Receive tasks',
      description:
        'The company will send tasks, <br> for which you will receive <br> experience and payment',
      btnTitle: 'Next',
      btnNext: 'swipe-task',
    },
    'swipe-task': {
      title: 'Swipe left to dismiss',
      description: '',
      btnTitle: 'Next',
      btnNext: 'play-task',
    },
    // 'cancel-task': {
    //   title: 'Reject the task',
    //   description: '',
    //   btnTitle: 'Next',
    //   btnNext: 'play-task',
    // },
    'play-task': {
      title: 'Start execution',
      description:
        'Time to complete is limited. <br> You can reject a task <br> if it is not suitable for you',
      btnTitle: 'Next',
      btnNext: 'start-tsk-person',
    },
  },
  implement: {
    persons: {
      title: 'Person',
    },
    location: {
      title: 'Environment',
    },
    shop: {
      dog: 'Dogs',
      cat: 'Cats',
      location: 'Locations',
    },
  },
  skills: {
    title: 'Skills',
    efficiency: {
      title: 'Efficiency',
    },
  },
  mission: {
    title: 'Missions',
    daily: {
      title: 'Daily',
    },
    oneTime: {
      title: 'oneTime',
    },
    addTextMissionItem: 'tomorrow',
  },
  missionSuccess: {
    title: 'Mission Complete',
    desc: 'Log in every day to increase your reward',
    label: 'Log in to the game',
    totalCountText: 'days in a row',
    labelNow: {
      subText: 'Now',
    },
    labelTomorrow: {
      subText: 'Tomorrow',
    },
    btnTitle: 'Claim reward',
  },
  friends: {
    title: 'Friends',
    description: `Invite your friends and earn ${FRIENDS_REWARD} in-game currency instantly, plus 10% of your friends' income daily!`,
    btnTitle: 'Invite Friend',
  },
  friendsShare: {
    text: "Hey! Check out this new game. I think we can actually make some real money here, not like in Hamster. I'm already playing, join me!",
  },
  upLevelInfo: {
    title: 'Level Up!',
    btnTitle: 'Continue',
  },
}
