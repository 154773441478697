<template>
  <div v-if="info" class="ntb-cn">
    <div v-if="info?.data?.iconName" class="ntb-cn__lf cs-df-alc-jcc">
      <CSPIcons :name-icon="info.data.iconName" />
    </div>
    <div class="ntb-cn__cnt cs-w100">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="info.title" class="ntb-cn__t" v-html="info.title"></div>
      <div v-if="info?.data?.subTitle" class="ntb-cn__sbt">
        <CSPIcons
          v-if="info?.data?.subIconName"
          :name-icon="info.data.subIconName"
        />
        <span>
          {{ info.data.subTitle }}
        </span>
      </div>
    </div>
    <div class="ntb-cn__rh">
      <BaseBtn
        v-if="info?.data?.btnTitle"
        mode="primary"
        size="small"
        max-width="80px"
        @click="
          emit('notification-btn', {
            idNotification: info.id,
            btnEvent: info?.data?.btnEvent,
            infoData: info?.data,
          })
        "
      >
        {{ info.data.btnTitle }}
      </BaseBtn>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  info: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits<{
  (
    e: 'notification-btn',
    info: { btnEvent: string; idNotification: number; infoData: object },
  ): void
}>()
</script>

<style lang="scss" scoped>
.ntb-cn {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 122, 255, 0.3);
  backdrop-filter: blur(calc(30px / 2));

  &__lf {
    width: 24px;
    margin-right: 8px;
    color: #fff;

    .i-icon {
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
    }
  }

  &__t {
    color: #fff;
    font-size: 16px;
    line-height: 150%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  &__sbt {
    display: grid;
    grid-template-columns: 16px 1fr;
    grid-gap: 4px;
    align-items: center;
    max-width: fit-content;
    padding: 2px 6px 2px 4px;
    border-radius: 999px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(calc(30px / 2));
    margin-top: 2px;

    .i-icon {
      width: 16px;
      min-width: 16px;
      max-width: 16px;
      height: 16px;
    }

    span {
      color: #fff;
      font-size: 14px;
      line-height: 142%;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  &__rh {
    margin-left: 12px;
  }
}
</style>
