<template>
  <!-- eslint-disable vue/no-v-text-v-html-on-component-->
  <p
    v-if="description"
    :class="[
      'cs-p',
      `cs-p--${mode}`,
      `cs-p--${color}`,
      { 'is-center': isTextCenter },
    ]"
    v-html="description"
  ></p>
</template>

<script>
export default {
  name: 'CSUParagraph',
  props: {
    description: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'p14',
    },
    color: {
      type: String,
      default: 'base-text-color',
    },
    isTextCenter: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.cs-p {
  line-height: var(--v-line-height);
  color: var(--v-color-text);

  :deep(a) {
    color: var(--brand-blue-action);
  }

  /* mode */
  $typesMode: (
    p20: 20px,
    p20media: 20px,
    p16: 16px,
    p14: 14px,
    p12: 12px,
    p10: 10px,
  );

  @each $mode, $size in $typesMode {
    &--#{$mode} {
      font-size: $size;
      --v-line-height: 140%;
    }
  }

  $typesColor: (
    base-text-color,
    base-text-color-white,
    base-text-color-secondary
  );

  @each $color in $typesColor {
    &--#{$color} {
      --v-color-text: var(--#{$color});
    }
  }

  &.is-center {
    text-align: center;
  }

  &--p20media {
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
</style>
