<template>
  <defaultLayout>
    <router-view />
  </defaultLayout>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useTelegramWebApp } from '@/services/useTelegramWebApp'
import { authUser } from '@/helpers/auth/auth-user'
import { isCheckPlatform } from '@/helpers/defaultUtils'
import defaultLayout from '@/layouts/defaultLayout.vue'

const router = useRouter()
const { ready, platform, userInfo, startParam } = useTelegramWebApp()
const isDevelopment = process.env.NODE_ENV === 'development'

onMounted(async () => {
  ready()

  if (!isCheckPlatform(platform) && !isDevelopment) {
    await router.push({ name: 'error', params: { code: 403 } })
  }

  const telegramUserInfo = {
    id: userInfo?.id || 0,
    first_name: userInfo?.first_name || '',
    last_name: userInfo?.last_name || '',
    username: userInfo?.username || '',
    language_code: userInfo?.language_code || 'ru',
  }

  // Данные только для локалки
  const LOCAL_TG_USER_INFO = {
    id: 5454541,
    first_name: 'first_name',
    last_name: 'last_name',
    username: 'username',
    language_code: 'ru',
  }

  // Приглашение
  const inviteID = startParam?.split('profileId-')[1]

  // Если не пришла инфа от телеги, то перезагрузить страницу
  if (!telegramUserInfo?.id && !isDevelopment) {
    window.location.reload()
    return
  }

  const isUserAuth = await authUser(
    isDevelopment ? LOCAL_TG_USER_INFO : telegramUserInfo,
    inviteID,
  )

  if (!isUserAuth) {
    await router.push({ name: 'error', params: { code: 401 } })
  }
})
</script>
