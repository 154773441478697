import Axios from 'axios'
import { api as usersApi } from '@/api/files/users'
import { api as tasksApi } from '@/api/files/task'
import { api as companiesApi } from '@/api/files/companies'
import { api as friendsApi } from '@/api/files/friends'
import { api as resourcesApi } from '@/api/files/resources'
import { api as missionApi } from '@/api/files/mission'
import { api as playersApi } from '@/api/files/players'
// import { getJWT } from '@/helpers/get-jwt'

export const apiDomain = `${window.location.origin}`
export const apiV1Url = '/api/v1/'

export const axios = Axios.create({
  baseURL: apiDomain,
  // headers: {
  //   Authorization: getJWT().accessToken ? `Bearer ${getJWT().accessToken}` : '',
  // },
})

/* not CRUD System */
export {
  usersApi,
  tasksApi,
  companiesApi,
  friendsApi,
  resourcesApi,
  missionApi,
  playersApi,
}
