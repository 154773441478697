import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIconContainer = _resolveComponent("BaseIconContainer")!
  const _component_CSPIcons = _resolveComponent("CSPIcons")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.be), _mergeProps({
    class: [
      `cp-btn cs-df-alc-jcc cp-btn--${_ctx.mode} cp-btn--${_ctx.size}`,
      { 'cp-btn--disabled': _ctx.disabledLink },
    ],
    style: _ctx.styles
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      (_ctx.icon && _ctx.isIconContainer)
        ? (_openBlock(), _createBlock(_component_BaseIconContainer, {
            key: 0,
            style: _normalizeStyle({ order: _ctx.orders.icon }),
            icon: _ctx.icon,
            "size-cn-icon": _ctx.sizeCnIcon,
            "width-icon": _ctx.widthIcon,
            "height-icon": _ctx.heightIcon
          }, null, 8, ["style", "icon", "size-cn-icon", "width-icon", "height-icon"]))
        : (_ctx.icon && !_ctx.isIconContainer)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              style: _normalizeStyle([{ order: _ctx.orders.icon }]),
              class: _normalizeClass([
        `cp-btn__icon cs-df-alc-jcc`,
        `cp-btn__icon--${_ctx.iconPlacement}`,
        { 'cp-btn__icon--not-margin': !_ctx.$slots.default?.length },
      ])
            }, [
              _createVNode(_component_CSPIcons, {
                "name-icon": _ctx.icon,
                style: _normalizeStyle([_ctx.iconStyles])
              }, null, 8, ["name-icon", "style"])
            ], 6))
          : _createCommentVNode("", true),
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: "cp-btn__tx",
            style: _normalizeStyle({ order: _ctx.orders.text })
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["class", "style"]))
}