import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

import { ref, watch } from 'vue'
import { NotificationItem } from '@kyvg/vue3-notification'
import { useLocale } from '@/services/useLocale'
import { useUserProfileStore } from '@/stores/user'
import useCurrentInstance from '@/helpers/useCurrentInstance'
import { playersApi } from '@/api'
import { TOOLTIP_LIST, HOLE_OVERLAY } from '@/helpers/tips/const'
import NotificationBaseContent from '@/components/base/notification/NotificationBaseContent.vue'
import NotificationSuccessContent from '@/components/base/notification/NotificationSuccessContent.vue'
import NotificationUIContent from '@/components/base/notification/NotificationUIContent.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CSNotification',
  props: {
  group: {
    type: String,
    required: true,
  },
  max: {
    type: Number,
    default: 2,
  },
},
  setup(__props) {



const { t } = useLocale()
const userProfileStore = useUserProfileStore()
const { proxy } = useCurrentInstance()
// isInvite - Фикс для задания ширины для нотификации
const isInvite = ref<boolean>(false)
const passiveIncome = ref<boolean>(false)

const notificationBtn = (info: {
  btnEvent: string
  idNotification: number
  infoData: object
}) => {
  if (info.btnEvent === 'startSearchJob') {
    userProfileStore.setOpenOverlay(HOLE_OVERLAY['hole-start-click'])
    userProfileStore.setOpenTooltip(TOOLTIP_LIST['start-click-person'])
    proxy.$alertNotification('base', info.idNotification, null)
  }
  // Приглашение друзей
  if (info.btnEvent === 'inviteFriend') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    earningsCollect(info.infoData?.subTitle)
    proxy.$alertNotification('base', info.idNotification, null)
  }
}

const resetIsInvite = (item: NotificationItem) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (item?.data?.btnEvent === 'inviteFriend') {
    setTimeout(() => {
      isInvite.value = false
    }, 5000)
  }
}

const earningsCollect = async (amount?: string) => {
  try {
    const {
      data: { profile },
    } = await playersApi.earningsCollect(passiveIncome.value)
    userProfileStore.setUser(profile)

    // Приглашение друзей, зачисление
    isInvite.value = true
    proxy.$alertNotification('success', null, {
      iconName: 'Checkbox',
      subTitle: `${amount}`,
      subIconName: 'Currency',
    })
  } catch (err) {
    console.log(err)
  } finally {
    passiveIncome.value = false
  }
}

const openNotificationFriends = (value: number) => {
  setTimeout(() => {
    proxy.$alertNotification('base', null, {
      title: t('notification.friends.title'),
      iconName: 'Notify',
      btnTitle: t('notification.friends.btnTitle'),
      btnEvent: 'inviteFriend',
      subTitle: `+${value}`,
      subIconName: 'Currency',
      duration: -1,
    })
  }, 3000)
}

watch(
  () => userProfileStore?.user?.events?.friendEarnings?.amount,
  (value, oldValue) => {
    if (value && value !== oldValue) {
      // Нотификация при приглашении
      openNotificationFriends(value)
      passiveIncome.value = false
    }
  },
)

watch(
  () => userProfileStore?.user?.events?.passiveIncome?.amount,
  (value, oldValue) => {
    if (value && value !== oldValue) {
      // Нотификация при приглашении
      openNotificationFriends(value)
      passiveIncome.value = true
    }
  },
)

return (_ctx: any,_cache: any) => {
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createBlock(_component_notifications, {
    class: _normalizeClass(["app-notification__wrapper", { isInvite: isInvite.value }]),
    classes: "app-notification",
    group: __props.group,
    max: __props.max,
    position: "bottom center",
    duration: -1,
    style: { '--animate-duration': '.1s' },
    onDestroy: resetIsInvite
  }, {
    body: _withCtx((props) => [
      _createVNode(_TransitionGroup, {
        name: "swipe-onboard-animation",
        "enter-active-class": "animate__animated animate__fadeIn",
        "leave-active-class": "animate__animated animate__fadeOut"
      }, {
        default: _withCtx(() => [
          (props.item.type === 'base')
            ? (_openBlock(), _createBlock(NotificationBaseContent, {
                key: props.item.id,
                info: props.item,
                onNotificationBtn: notificationBtn
              }, null, 8, ["info"]))
            : _createCommentVNode("", true),
          (props.item.type === 'success')
            ? (_openBlock(), _createBlock(NotificationSuccessContent, {
                key: props.item.id,
                info: props.item
              }, null, 8, ["info"]))
            : _createCommentVNode("", true),
          (props.item.type === 'ui')
            ? (_openBlock(), _createBlock(NotificationUIContent, {
                key: props.item.id,
                info: props.item,
                onClose: props.close
              }, null, 8, ["info", "onClose"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["class", "group", "max"]))
}
}

})