<template>
  <div class="ntb-ui">
    <div v-if="info?.data?.iconName" class="ntb-ui__lf cs-df-alc-jcc">
      <CSPIcons :name-icon="info.data.iconName" />
    </div>
    <div class="ntb-ui__cn cs-w100">
      <div v-if="info.title" class="ntb-ui__t">{{ info.title }}</div>
    </div>
    <div class="ntb-ui__close cs-df-alc-jcc">
      <SBtn icon="Close" @click="emit('close')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import SBtn from '@/components/base/buttons/SBtn.vue'

defineProps({
  info: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits<{
  (e: 'close'): void
}>()
</script>

<style lang="scss" scoped>
.ntb-ui {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(24, 255, 0, 0.3);
  backdrop-filter: blur(calc(30px / 2));

  &__lf {
    width: 24px;
    margin-right: 8px;
    color: #fff;

    .i-icon {
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
    }
  }

  &__t {
    color: #fff;
    font-size: 16px;
    line-height: 150%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  &__close {
    margin-left: 12px;
  }
}
</style>
