import { axios, apiV1Url } from '@/api'
import { UserAuth } from '@/types/user/UserAuth'

export const api = {
  userAuth(info: UserAuth | null, inviteID?: string) {
    const url = inviteID ? `auth/login?tid=${inviteID}` : 'auth/login'
    return axios.post(`${apiV1Url}${url}`, info)
  },
  async getMe() {
    return axios.get(`${apiV1Url}players/profile`)
  },
  async updateProfile(info: object) {
    return axios.patch(`${apiV1Url}players/profile`, info)
  },
  async deleteProfile() {
    return axios.delete(`${apiV1Url}players/profile`)
  },
  // Повторяет ручку statusTask т.к. прежде была другая логика
  nextTask(taskId: string, status: string) {
    return axios.post(`${apiV1Url}players/task/${taskId}/${status}`)
  },
  userIsOnboarding(isOnboarding: boolean) {
    return axios.patch(`${apiV1Url}players/onboarding/${isOnboarding}`)
  },
  userIsJobSearch(isJobSearch: boolean) {
    return axios.patch(`${apiV1Url}players/jobsearch/${isJobSearch}`)
  },
}
