import { i18n } from '../i18n'

export function useLocale(): UseLocale {
  function getCurrentLocale(): string {
    return i18n.global.locale.value
  }
  /**
   * Returns localised message
   * @param path - message path
   */
  function t(path: string): string {
    return i18n.global.t(path)
  }

  /**
   * Updates app locale
   * @param locale - new locale value
   */
  function setLocale(locale: string): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n.global.locale.value = locale
  }

  return {
    getCurrentLocale,
    t,
    setLocale,
  }
}

interface UseLocale {
  getCurrentLocale: () => string
  t: (path: string) => string
  setLocale: (value: string) => void
}
