import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ntb-cn"
}
const _hoisted_2 = {
  key: 0,
  class: "ntb-cn__lf cs-df-alc-jcc"
}
const _hoisted_3 = { class: "ntb-cn__cnt cs-w100" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "ntb-cn__sbt"
}
const _hoisted_6 = { class: "ntb-cn__rh" }


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationBaseContent',
  props: {
  info: {
    type: Object,
    default: null,
  },
},
  emits: ["notification-btn"],
  setup(__props, { emit: __emit }) {



const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_CSPIcons = _resolveComponent("CSPIcons")!
  const _component_BaseBtn = _resolveComponent("BaseBtn")!

  return (__props.info)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (__props.info?.data?.iconName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_CSPIcons, {
                "name-icon": __props.info.data.iconName
              }, null, 8, ["name-icon"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (__props.info.title)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "ntb-cn__t",
                innerHTML: __props.info.title
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          (__props.info?.data?.subTitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (__props.info?.data?.subIconName)
                  ? (_openBlock(), _createBlock(_component_CSPIcons, {
                      key: 0,
                      "name-icon": __props.info.data.subIconName
                    }, null, 8, ["name-icon"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(__props.info.data.subTitle), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (__props.info?.data?.btnTitle)
            ? (_openBlock(), _createBlock(_component_BaseBtn, {
                key: 0,
                mode: "primary",
                size: "small",
                "max-width": "80px",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (
          emit('notification-btn', {
            idNotification: __props.info.id,
            btnEvent: __props.info?.data?.btnEvent,
            infoData: __props.info?.data,
          })
        ))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.info.data.btnTitle), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})