<template>
  <div v-if="missionInfo" class="ms-sc cs-h100">
    <ViewMobileContainer is-visible-btn btn-position-sticky is-scroll-wrapper>
      <template #content-container>
        <div class="ms-sc__img">
          <img :src="missionSuccessImg" alt="" />
        </div>

        <div class="ms-sc__t">{{ t(`missionSuccess.title`) }}</div>
        <div class="ms-sc__d">
          {{ t(`missionSuccess.desc`) }}
        </div>

        <div class="ms-sc__bl cs-mb-12">
          <div class="ms-sc__lb">{{ t(`missionSuccess.label`) }}</div>
          <div class="ms-sc__ds">
            <LabelCount
              mode="ms"
              :current-value="missionInfo?.days?.out || 0"
              :total-value="`${missionInfo?.days?.total || 0} ${t(`missionSuccess.totalCountText`)}`"
            />
          </div>
        </div>

        <div class="ms-sc__bl">
          <LabelInfo
            mode="ms-success-now"
            name-icon="Currency"
            :text="`+${missionInfo?.reward?.today || 0}`"
            :sub-text="t(`missionSuccess.labelNow.subText`)"
          />
          <LabelInfo
            mode="ms-success-tmr"
            name-icon="Currency"
            :text="`+${missionInfo?.reward?.tomorrow || 0}`"
            :sub-text="t(`missionSuccess.labelTomorrow.subText`)"
          />
        </div>
      </template>
      <template #btn-container>
        <BaseBtn icon="Currency" is-icon-container w100 @click="rewardMission">
          {{ t(`missionSuccess.btnTitle`) }}
        </BaseBtn>
      </template>
    </ViewMobileContainer>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useLocale } from '@/services/useLocale'
import { missionApi } from '@/api'
import { User } from '@/types/user/User'
import ViewMobileContainer from '@/components/ui/container/ViewMobileContainer.vue'
import LabelCount from '@/components/ui/label-info/LabelCount.vue'
import LabelInfo from '@/components/ui/label-info/LabelInfo.vue'

const props = defineProps({
  missionInfo: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits<{
  (e: 'reward-mission', userInfo: User): void
}>()

const { t } = useLocale()

const missionSuccessImg = computed(() =>
  require('@/assets/images/mission/mission-success.png'),
)

const rewardMission = async () => {
  try {
    const {
      data: { profile },
    } = await missionApi.rewardMission(props.missionInfo.id)
    emit('reward-mission', profile)
  } catch (error) {
    console.log(error)
  }
}
</script>

<style lang="scss" scoped>
.ms-sc {
  &__img {
    width: 128px;
    height: 128px;
    margin: 0 auto 32px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__t {
    color: #fff;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 128.571%;
    letter-spacing: -0.084px;
    margin-bottom: 16px;
  }

  &__d {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 32px;
  }

  &__bl {
    display: flex;
    flex-direction: column;
    align-items: center;

    :deep(.lbi--ms-success-tmr) {
      margin-top: -6px;
    }
  }

  &__lb {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 4px;
  }
}
</style>
