import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/Main.vue'),
  },
  {
    path: '/friends',
    name: 'friends',
    component: () => import('@/views/Friends.vue'),
  },
  // {
  //   path: '/implements',
  //   name: 'implements',
  //   component: () => import('@/views/Implements.vue'),
  // },
  {
    path: '/mission',
    name: 'mission',
    component: () => import('@/views/Mission.vue'),
  },
  {
    path: '/error/:code',
    name: 'error',
    component: () => import('@/views/Error.vue'),
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/Error.vue'),
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: 'smooth' })
  },
})

export default router
