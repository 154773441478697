import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "lbi__img cs-df-alc-jcc"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "lbi__bl" }
const _hoisted_4 = {
  key: 0,
  class: "lbi__stxt txt-ovh-1line-clamp"
}
const _hoisted_5 = { class: "lbi__txt txt-ovh-1line-clamp" }
const _hoisted_6 = {
  key: 0,
  class: "sm-tx"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LabelInfo',
  props: {
  mode: {
    type: String,
    default: 'df',
  },
  nameIcon: {
    type: String,
    default: '',
  },
  text: {
    type: [String, Number],
    default: '',
  },
  addText: {
    type: [String, Number],
    default: '',
  },
  subText: {
    type: [String, Number],
    default: '',
  },
  imgSrc: {
    type: String,
    default: '',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_CSPIcons = _resolveComponent("CSPIcons")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["lbi", [`lbi--${__props.mode}`]])
  }, [
    (__props.nameIcon)
      ? (_openBlock(), _createBlock(_component_CSPIcons, {
          key: 0,
          "name-icon": __props.nameIcon
        }, null, 8, ["name-icon"]))
      : _createCommentVNode("", true),
    (__props.imgSrc)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createElementVNode("img", {
            src: __props.imgSrc,
            alt: ""
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, [
      (__props.subText)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.subText), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_5, [
        _createTextVNode(_toDisplayString(__props.text) + " ", 1),
        (__props.addText)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(__props.addText), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
}

})