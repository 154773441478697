import { createI18n } from 'vue-i18n'
import en from './en'
import ru from './ru'

export const i18n = createI18n({
  warnHtmlMessage: false,
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'ru',
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    en,
    ru,
  },
})
