<template>
  <component
    :is="be"
    :class="[
      `cp-btn cs-df-alc-jcc cp-btn--${mode} cp-btn--${size}`,
      { 'cp-btn--disabled': disabledLink },
    ]"
    :style="styles"
    v-bind="$attrs"
  >
    <BaseIconContainer
      v-if="icon && isIconContainer"
      :style="{ order: orders.icon }"
      :icon="icon"
      :size-cn-icon="sizeCnIcon"
      :width-icon="widthIcon"
      :height-icon="heightIcon"
    />
    <span
      v-else-if="icon && !isIconContainer"
      :style="[{ order: orders.icon }]"
      :class="[
        `cp-btn__icon cs-df-alc-jcc`,
        `cp-btn__icon--${iconPlacement}`,
        { 'cp-btn__icon--not-margin': !$slots.default?.length },
      ]"
    >
      <CSPIcons :name-icon="icon" :style="[iconStyles]" />
    </span>
    <span
      v-if="$slots.default"
      class="cp-btn__tx"
      :style="{ order: orders.text }"
    >
      <slot></slot>
    </span>
  </component>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import BaseIconContainer from '@/components/base/buttons/BaseIconContainer.vue'

export default defineComponent({
  name: 'BaseBtn',
  components: {
    BaseIconContainer,
  },
  props: {
    be: {
      type: String,
      default: 'button',
    },
    mode: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'middle',
    },
    w100: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconPlacement: {
      type: String,
      default: 'before',
    },
    sizeCnIcon: {
      type: String,
      default: 'middle',
    },
    isIconContainer: {
      type: Boolean,
      default: false,
    },
    widthIcon: {
      type: String,
      default: '',
    },
    heightIcon: {
      type: String,
      default: '',
    },
    disabledLink: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const styles = computed(() => {
      return {
        minWidth: props.minWidth || null,
        maxWidth: props.maxWidth || null,
        width: props.w100 ? '100%' : null,
      }
    })

    const orders = computed(() => {
      switch (props.iconPlacement) {
        case 'before':
          return {
            icon: 1,
            text: 2,
          }
        case 'after':
        default:
          return {
            icon: 2,
            text: 1,
          }
      }
    })

    const iconStyles = computed(() => {
      return {
        width: props.widthIcon || null,
        height: props.heightIcon || null,
      }
    })

    return {
      styles,
      orders,
      iconStyles,
    }
  },
})
</script>

<style lang="scss" scoped>
.cp-btn {
  border: none;
  box-shadow: none;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.5px;
  transition: all 0.3s ease;

  background-color: var(--v-btn-bg-color);
  border: var(--v-btn-border);
  color: var(--v-btn-text-color);

  width: var(--v-btn-width);
  min-height: var(--v-btn-height);
  max-height: var(--v-btn-height);
  padding: var(--v-btn-padding);
  font-size: var(--v-btn-font-size);
  border-radius: var(--v-btn-border-radius);

  &:hover,
  &:focus {
    background-color: var(--v-btn-hf-bg-color);
    border: var(--v-btn-hf-border);
    color: var(--v-btn-hf-text-color);
  }

  &:disabled,
  &[disabled] {
    background: var(--v-btn-disabled-bg-color);
    color: var(--v-btn-disabled-text-color);
    border: var(--v-btn-disabled-border);
    cursor: no-drop;
    opacity: 0.3;

    &:focus,
    &:hover {
      border: var(--v-btn-disabled-border);
    }
  }

  //&__tx {
  //  display: -webkit-box;
  //  -webkit-box-orient: vertical;
  //  -webkit-line-clamp: 1;
  //  overflow: hidden;
  //}

  &__icon {
    &--before {
      margin-right: var(--v-btn-margin);
    }
    &--after {
      margin-left: var(--v-btn-margin);
    }
    &--not-margin {
      margin-right: 0;
      margin-left: 0;
    }
  }

  /*size button*/
  &--small {
    --v-btn-padding: 4px 8px;
    --v-btn-font-size: 14px;
    --v-btn-border-radius: 14px;
    --v-btn-height: 28px;
    --v-btn-margin: 4px;
  }

  &--middle {
    --v-btn-padding: 14px 16px;
    --v-btn-font-size: 16px;
    --v-btn-border-radius: 12px;
    --v-btn-height: 52px;
    --v-btn-margin: 6px;
  }

  &--large {
    --v-btn-padding: 6px 20px;
    --v-btn-font-size: 16px;
    --v-btn-border-radius: 12px;
    --v-btn-height: 64px;
    --v-btn-margin: 8px;
  }
  /*size button*/

  /*mode button*/
  /* пока массив назначать здесь, потом может вынести отдельно в глобальную область */
  $typesMode: primary, primary-scn, delete, success, bg-transparent;

  @each $mode in $typesMode {
    &--#{$mode} {
      /*active*/
      --v-btn-bg-color: var(--btn-#{$mode}-bg);
      --v-btn-text-color: var(--btn-#{$mode}-text-color);
      --v-btn-border: var(--btn-#{$mode}-border);

      /*hover and focus*/
      --v-btn-hf-bg-color: var(--btn-#{$mode}-hover-bg);
      --v-btn-hf-text-color: var(--btn-#{$mode}-hover-text-color);
      --v-btn-hf-border: var(--btn-#{$mode}-border-hover);

      /*disabled*/
      --v-btn-disabled-bg-color: var(--btn-#{$mode}-disabled-bg-color);
      --v-btn-disabled-text-color: var(--btn-#{$mode}-disabled-text-color);
      --v-btn-disabled-border: var(--btn-#{$mode}-border-disabled);
    }
  }

  &--link {
    padding: 8px;

    &:disabled,
    &[disabled] {
      opacity: 0.2;
    }
  }
  /*mode button*/
}
</style>
