import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ntb-ui" }
const _hoisted_2 = {
  key: 0,
  class: "ntb-ui__lf cs-df-alc-jcc"
}
const _hoisted_3 = { class: "ntb-ui__cn cs-w100" }
const _hoisted_4 = {
  key: 0,
  class: "ntb-ui__t"
}
const _hoisted_5 = { class: "ntb-ui__close cs-df-alc-jcc" }

import SBtn from '@/components/base/buttons/SBtn.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationUIContent',
  props: {
  info: {
    type: Object,
    default: null,
  },
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {



const emit = __emit

return (_ctx: any,_cache: any) => {
  const _component_CSPIcons = _resolveComponent("CSPIcons")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.info?.data?.iconName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CSPIcons, {
            "name-icon": __props.info.data.iconName
          }, null, 8, ["name-icon"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (__props.info.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(__props.info.title), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(SBtn, {
        icon: "Close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
      })
    ])
  ]))
}
}

})