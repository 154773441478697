<template>
  <notifications
    class="app-notification__wrapper"
    classes="app-notification"
    :class="{ isInvite: isInvite }"
    :group="group"
    :max="max"
    position="bottom center"
    :duration="-1"
    :style="{ '--animate-duration': '.1s' }"
    @destroy="resetIsInvite"
  >
    <template #body="props">
      <transition-group
        name="swipe-onboard-animation"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <NotificationBaseContent
          v-if="props.item.type === 'base'"
          :key="props.item.id"
          :info="props.item"
          @notification-btn="notificationBtn"
        />

        <NotificationSuccessContent
          v-if="props.item.type === 'success'"
          :key="props.item.id"
          :info="props.item"
        />

        <NotificationUIContent
          v-if="props.item.type === 'ui'"
          :key="props.item.id"
          :info="props.item"
          @close="props.close"
        />
      </transition-group>
    </template>
  </notifications>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { NotificationItem } from '@kyvg/vue3-notification'
import { useLocale } from '@/services/useLocale'
import { useUserProfileStore } from '@/stores/user'
import useCurrentInstance from '@/helpers/useCurrentInstance'
import { playersApi } from '@/api'
import { TOOLTIP_LIST, HOLE_OVERLAY } from '@/helpers/tips/const'
import NotificationBaseContent from '@/components/base/notification/NotificationBaseContent.vue'
import NotificationSuccessContent from '@/components/base/notification/NotificationSuccessContent.vue'
import NotificationUIContent from '@/components/base/notification/NotificationUIContent.vue'

defineProps({
  group: {
    type: String,
    required: true,
  },
  max: {
    type: Number,
    default: 2,
  },
})

const { t } = useLocale()
const userProfileStore = useUserProfileStore()
const { proxy } = useCurrentInstance()
// isInvite - Фикс для задания ширины для нотификации
const isInvite = ref<boolean>(false)
const passiveIncome = ref<boolean>(false)

const notificationBtn = (info: {
  btnEvent: string
  idNotification: number
  infoData: object
}) => {
  if (info.btnEvent === 'startSearchJob') {
    userProfileStore.setOpenOverlay(HOLE_OVERLAY['hole-start-click'])
    userProfileStore.setOpenTooltip(TOOLTIP_LIST['start-click-person'])
    proxy.$alertNotification('base', info.idNotification, null)
  }
  // Приглашение друзей
  if (info.btnEvent === 'inviteFriend') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    earningsCollect(info.infoData?.subTitle)
    proxy.$alertNotification('base', info.idNotification, null)
  }
}

const resetIsInvite = (item: NotificationItem) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (item?.data?.btnEvent === 'inviteFriend') {
    setTimeout(() => {
      isInvite.value = false
    }, 5000)
  }
}

const earningsCollect = async (amount?: string) => {
  try {
    const {
      data: { profile },
    } = await playersApi.earningsCollect(passiveIncome.value)
    userProfileStore.setUser(profile)

    // Приглашение друзей, зачисление
    isInvite.value = true
    proxy.$alertNotification('success', null, {
      iconName: 'Checkbox',
      subTitle: `${amount}`,
      subIconName: 'Currency',
    })
  } catch (err) {
    console.log(err)
  } finally {
    passiveIncome.value = false
  }
}

const openNotificationFriends = (value: number) => {
  setTimeout(() => {
    proxy.$alertNotification('base', null, {
      title: t('notification.friends.title'),
      iconName: 'Notify',
      btnTitle: t('notification.friends.btnTitle'),
      btnEvent: 'inviteFriend',
      subTitle: `+${value}`,
      subIconName: 'Currency',
      duration: -1,
    })
  }, 3000)
}

watch(
  () => userProfileStore?.user?.events?.friendEarnings?.amount,
  (value, oldValue) => {
    if (value && value !== oldValue) {
      // Нотификация при приглашении
      openNotificationFriends(value)
      passiveIncome.value = false
    }
  },
)

watch(
  () => userProfileStore?.user?.events?.passiveIncome?.amount,
  (value, oldValue) => {
    if (value && value !== oldValue) {
      // Нотификация при приглашении
      openNotificationFriends(value)
      passiveIncome.value = true
    }
  },
)
</script>

<style lang="scss">
.app-notification {
  &__wrapper {
    width: 100% !important;
    min-width: 272px;
    max-width: 272px;
    left: 50% !important;
    transform: translateX(-50%);
    bottom: 95px !important;
    z-index: 100001 !important;

    &.isInvite {
      min-width: 158px;
      max-width: 158px;
    }

    .vue-notification-wrapper {
      overflow: visible;
      margin: 0 0 10px !important;
    }
  }
}
</style>
