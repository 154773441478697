import { FRIENDS_REWARD } from '@/helpers/const'

export default {
  main_loading: {
    title: 'Well Payed',
    description: 'Стань самым успешным <br> в Биствилле',
    loading: 'Загрузка',
  },
  profile: {
    settings: {
      title: 'Настройки',
      telegram: {
        title: 'Telegram ID',
      },
      lang: {
        title: 'Язык',
      },
      support: {
        title: 'Сообщить о проблеме',
      },
      deleteAccount: {
        title: 'Удалить аккаунт',
      },
      popupDelete: {
        title: 'Вы уверены, что хотите удалить аккаунт?',
        description:
          'Ваши данные, включая прогресс в игре, достижения и покупки, будут безвозвратно удалены. Это действие нельзя отменить.',
        btnOneTitle: 'Удалить аккаунт',
        btnTwoTitle: 'Отменить',
      },
      notification: {
        copyTitle: 'Cкопировано',
      },
    },
  },
  company: {
    firstOffer: {
      title: 'Получен оффер в',
      description: 'Примите оффер в $companyNames или продолжите поиск работы',
      bonusTitle: 'Параметры компании',
      labelOneText: 'к опыту',
      labelTwoText: 'к оплате',
      btnTitleOne: 'Принять',
      btnTitleTwo: '',
    },
    secondOffer: {
      title: 'Получен оффер в',
      description: 'Примите оффер в $companyNames или продолжите поиск работы',
      bonusTitle: 'Параметры компании',
      labelOneText: 'к опыту',
      labelTwoText: 'к оплате',
      btnTitleOne: 'Принять',
      btnTitleTwo: 'Продолжить поиск',
    },
    list: {
      title: 'Компании',
      subTitle: 'Все компании',
      btnTitle: 'Сменить компанию',
    },
  },
  notification: {
    startJobSearch: {
      title: 'Доступен поиск <br> новой работы',
      btnTitle: 'Начать',
    },
    jobSearch: {
      title: 'Шанс найти работу',
    },
    friends: {
      title: 'Доход от друзей',
      btnTitle: 'Забрать',
    },
  },
  tooltip: {
    'start-click-person': 'Тапай на персонажа, <br> чтоб искать работу',
    'start-tsk-person':
      'Тапайте на персонажа, чтобы приступить <br> к выполнению задания',
    'start-company': {
      title: 'Поздравляем с первой <br> работой!',
      description:
        'Теперь вы работаете в <br> компании, которая будет <br> присылать вам задачи',
      btnTitle: 'Далее',
      btnNext: 'start-task',
    },
    'start-task': {
      title: 'Получайте задачи',
      description:
        'Компания будет присылать задачи, <br> за выполнение которых вы получите <br> опыт и оплату',
      btnTitle: 'Далее',
      btnNext: 'swipe-task',
    },
    'swipe-task': {
      title: 'Свайп влево, чтоб отклонить',
      description: '',
      btnTitle: 'Далее',
      btnNext: 'play-task',
    },
    // 'cancel-task': {
    //   title: 'Отклоните задачу',
    //   description: '',
    //   btnTitle: 'Далее',
    //   btnNext: 'play-task',
    // },
    'play-task': {
      title: 'Начните выполнение',
      description:
        'Время на выполнение ограничено. <br> Вы можете отклонить задачу, <br> если она вам не подходит',
      btnTitle: 'Далее',
      btnNext: 'start-tsk-person',
    },
  },
  implement: {
    persons: {
      title: 'Персонажи',
    },
    location: {
      title: 'Окружение',
    },
    shop: {
      dog: 'Собаки',
      cat: 'Коты',
      location: 'Окружение',
    },
  },
  skills: {
    title: 'Навыки',
    efficiency: {
      title: 'Эффективность',
    },
  },
  mission: {
    title: 'Миссии',
    daily: {
      title: 'Ежедневные',
    },
    oneTime: {
      title: 'Разовые',
    },
    addTextMissionItem: 'завтра',
  },
  missionSuccess: {
    title: 'Миссия выполнена',
    desc: 'Заходите в игру каждый день, чтобы увеличивать свою награду',
    label: 'Войти в игру',
    totalCountText: 'дней подряд',
    labelNow: {
      subText: 'Сейчас',
    },
    labelTomorrow: {
      subText: 'Завтра',
    },
    btnTitle: 'Забрать награду',
  },
  friends: {
    title: 'Друзья',
    description: `Приглашайте друзей и получайте ${FRIENDS_REWARD} внутренней валюты сразу и 10% от дохода друзей ежедневно!`,
    btnTitle: 'Пригласить друга',
  },
  friendsShare: {
    text: 'Привет! Зацени новую игру. Думаю там реально можно заработать, не то что в Хомяке. Я уже играю, погнали со мной',
  },
  upLevelInfo: {
    title: 'Уровень повышен!',
    btnTitle: 'Продолжить',
  },
}
