import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

import { watch } from 'vue'
import { useTitle } from '@vueuse/core'
import { useTelegramWebApp } from '@/services/useTelegramWebApp'
import usePopupToShow from '@/helpers/usePopupToShow'
import { useUserProfileStore } from '@/stores/user'
import CSNotification from '@/components/base/notification/CSNotification.vue'
import CSPopup from '@/components/base/popup/CSPopup.vue'
import MissionSuccess from '@/components/mission/MissionSuccess.vue'
import { User } from '@/types/user/User'


export default /*@__PURE__*/_defineComponent({
  __name: 'defaultLayout',
  setup(__props) {

useTitle('Well Payed')
const { getViewportHeight } = useTelegramWebApp()
const { popupToShow, openPopup, hidePopup } = usePopupToShow()
const userProfileStore = useUserProfileStore()

const rewardMission = (userInfo: User) => {
  userProfileStore.setUser(userInfo)
  hidePopup()
}

watch(
  () => userProfileStore?.user?.events?.missions?.dailyLoginMission,
  (value) => {
    if (
      value &&
      !userProfileStore?.user?.isJobSearch &&
      !userProfileStore?.user?.events?.friendEarnings?.amount &&
      !userProfileStore?.user?.events?.passiveIncome?.amount
    ) {
      setTimeout(() => {
        openPopup('popupMission')
      }, 4000)
    }
  },
)

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: "cs-app",
    style: _normalizeStyle({ '--cs-tg-height': `${_unref(getViewportHeight)()}px` })
  }, [
    _createVNode(_component_router_view),
    _createVNode(CSNotification, {
      ref: "notificationRef",
      group: "notificationGroup"
    }, null, 512),
    (_unref(popupToShow) === 'popupMission')
      ? (_openBlock(), _createBlock(CSPopup, {
          key: 0,
          mode: "mobile",
          "max-width": "480px",
          "not-padding": "",
          "disable-on-click-outside": "",
          onClose: _unref(hidePopup)
        }, {
          body: _withCtx(() => [
            _createVNode(MissionSuccess, {
              "mission-info": 
            _unref(userProfileStore)?.user?.events?.missions?.dailyLoginMission || null
          ,
              onRewardMission: _cache[0] || (_cache[0] = ($event: any) => (rewardMission($event)))
            }, null, 8, ["mission-info"])
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 4))
}
}

})