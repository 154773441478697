import { app } from './plugins/main-app'
import router from './router'
import { i18n } from './i18n'
import { createPinia } from 'pinia'
import './plugins/global-components'
import './plugins/vue3-notification'
import './plugins/floating-vue'
import './plugins/vue3-touch-events'
import alertNotification from './plugins/alert-notification'
import './registerServiceWorker'

app.use(router)
app.use(i18n)
app.use(createPinia())
app.use(alertNotification)
app.mount('#app')
