import { axios, apiV1Url } from '@/api'

export const api = {
  playersTap(info: object) {
    return axios.post(`${apiV1Url}players/tap`, info)
  },
  playersOffer() {
    return axios.get(`${apiV1Url}players/offer`)
  },
  playersBindOffer(info: object) {
    return axios.post(`${apiV1Url}players/offer`, info)
  },
  earningsCollect(passiveIncome: boolean) {
    const url = passiveIncome
      ? 'players/earnings/collect?passiveIncome=true'
      : 'players/earnings/collect'
    return axios.post(`${apiV1Url}${url}`)
  },
}
