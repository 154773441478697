<template>
  <!-- eslint-disable vue/no-v-text-v-html-on-component-->
  <component
    :is="be"
    v-if="title"
    :class="[
      'cs-t',
      `cs-t--${mode}`,
      `cs-t--${color}`,
      `cs-t--${textTransform}`,
      `cs-t--${fontWeight}`,
      { 'is-popup': isPopup, 'is-center': isTextCenter },
    ]"
    v-html="title"
    data-testid="csu-title"
  ></component>
</template>

<script>
export default {
  name: 'CSUTitle',
  props: {
    be: {
      type: String,
      default: 'h2',
    },
    title: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'title28',
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'base-text-color',
    },
    textTransform: {
      type: String,
      default: 'initial',
    },
    fontWeight: {
      type: Number,
      default: 700,
    },
    isTextCenter: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.cs-t {
  line-height: var(--v-line-height);
  letter-spacing: var(--v-letter-spacing);

  /* mode */
  $typesMode: (
    title96: 96px,
    title96media: 96px,
    title80: 80px,
    title44: 44px,
    title44media: 44px,
    title40: 40px,
    title40media: 40px,
    title36: 36px,
    title36media: 36px,
    title32: 32px,
    title32job: 32px,
    title32media: 32px,
    title28: 28px,
    title24: 24px,
    title22: 22px,
    title20: 20px,
    title20media: 20px,
    title18: 18px,
    title16: 16px,
    title14: 14px,
    title12: 12px,
    title10: 10px,
  );

  @each $mode, $size in $typesMode {
    &--#{$mode} {
      font-size: $size;
      --v-letter-spacing: -0.5px;
    }
  }

  $typesColor: (
    base-text-color,
    base-text-color-white,
    base-text-color-secondary
  );

  @each $color in $typesColor {
    &--#{$color} {
      color: var(--#{$color});
    }
  }

  $typesTextTransform: (initial, uppercase);

  @each $tx in $typesTextTransform {
    &--#{$tx} {
      text-transform: #{$tx};
    }
  }

  $typesFontWeight: (normal, 500, 700, 900);

  @each $fw in $typesFontWeight {
    &--#{$fw} {
      font-weight: #{$fw};
    }
  }

  &--title96,
  &--title96media,
  &--title80,
  &--title44,
  &--title44media,
  &--title40,
  &--title40media,
  &--title36,
  &--title36media,
  &--title32,
  &--title32job,
  &--title32media {
    --v-line-height: 94%;
  }
  &--title28 {
    line-height: 128%;
    letter-spacing: -0.084px;
  }
  &--title22,
  &--title20,
  &--title16,
  &--title14,
  &--title12,
  &--title10 {
    --v-line-height: 140%;
    letter-spacing: -0.02px;
  }

  &--title12 {
    --v-letter-spacing: 0.16px;
  }

  &.is-popup {
    width: calc(100% - 30px);
  }

  &.is-center {
    text-align: center;
  }

  /* Для заголовков на мобиле переходит в 32px */
  &--title44media,
  &--title40media,
  &--title36media {
    @media (max-width: 767px) {
      font-size: 32px;
    }
  }
}
</style>
