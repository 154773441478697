import { defineStore } from 'pinia'
import { ref } from 'vue'
import disableScroll from 'disable-scroll'
import { watchTriggerable } from '@vueuse/core'
import { useStorage } from '@vueuse/core'
import { jobsSearchAnimate } from '@/helpers/clicker/job-search'
import { HOLE_OVERLAY, TOOLTIP_LIST } from '@/helpers/tips/const'
import { NOTIFICATION_TASK_RESULT } from '@/helpers/task/const'
import { usersApi, playersApi } from '@/api'
import { User } from '@/types/user/User'
import { Task } from '@/types/task/Task'
import { TaskPlayerStatus } from '@/types/task/TaskPlayerStatus'
import { ResultTask } from '@/types/task/ResultTask'
import { KeyCompanyOffer } from '@/types/company/KeyCompanyOffer'

export const useUserProfileStore = defineStore('userStore', () => {
  const isMainLoading = ref<boolean>(true)

  const user = ref<User | null>(null)
  const offerCompanyInfo = ref(null)

  const clickerCount = ref<number>(0)
  const jobSearchProgressCount = ref<number>(0)

  const taskInfo = ref<Task | null>(null)
  const resultTask = ref<ResultTask | null>(null)
  const isStartTask = ref<boolean>(false)

  const openOverlay = ref<string | null>(null)
  const openTooltip = ref<string | null>(null)

  const keyCompanyOffer = useStorage(
    'keyCompanyOffer',
    KeyCompanyOffer.firstOffer,
  )

  const setUser = (info: User | null) => {
    user.value = info
    setTask()
  }

  const setJobSearch = async (vl: boolean) => {
    if (!user.value) return
    user.value.isJobSearch = vl

    try {
      await usersApi.userIsJobSearch(vl)
      resetJobSearchProgressCount()
      resetClickerCount()
    } catch (err) {
      console.log(err)
    }
  }

  const setOnboard = async (vl: boolean) => {
    if (!user.value) return
    user.value.isOnboard = vl

    try {
      await usersApi.userIsOnboarding(vl)
    } catch (err) {
      console.log(err)
    }
  }

  const setTask = () => (taskInfo.value = user.value?.company?.task || null)

  const resetTask = () => (taskInfo.value = null)

  const setResultTask = (vl: ResultTask | null) => {
    resultTask.value = vl
  }

  const endTask = (taskStatus?: string) => {
    const tskStatus = taskStatus || TaskPlayerStatus.failed
    setResultTask({
      mode: tskStatus,
      title: taskInfo.value?.title,
      experience: taskInfo.value?.experience,
      fee: taskInfo.value?.fee,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...NOTIFICATION_TASK_RESULT[`${tskStatus}`],
    })
    resetTask()
  }

  const setClickerCount = () =>
    (clickerCount.value += user.value?.level?.basicEfficiency || 1)

  const resetClickerCount = () => (clickerCount.value = 0)

  const resetJobSearchProgressCount = () => (jobSearchProgressCount.value = 0)

  const setOpenOverlay = (vl: string | null) => (openOverlay.value = vl)

  const setOpenTooltip = (vl: string | null) => {
    if (vl === TOOLTIP_LIST['start-tsk-person']) {
      setOpenOverlay(HOLE_OVERLAY['hole-start-click'])
    }
    openTooltip.value = vl
  }

  const getUserData = async () => {
    try {
      const {
        data: { profile },
      } = await usersApi.getMe()
      await setUser(profile)
    } catch (err) {
      console.log(err)
    }
  }

  const playersTap = async () => {
    try {
      const {
        data: { profile },
      } = await playersApi.playersTap({ tap: clickerCount.value })
      setUser(profile)
    } catch (err) {
      console.log(err)
    }
  }

  const nextTask = async (taskStatus: string) => {
    const taskId = user.value?.company?.task?.id
    if (!taskId) return

    try {
      setResultTask(null)
      const {
        data: { profile },
      } = await usersApi.nextTask(taskId, taskStatus)
      await setUser(profile)
    } catch (err) {
      console.log(err)
    }
  }

  const updateProfile = async (info: object) => {
    try {
      const {
        data: { profile },
      } = await usersApi.updateProfile(info)
      await setUser(profile)
    } catch (err) {
      console.log(err)
    }
  }

  const deleteProfile = async () => {
    try {
      await usersApi.deleteProfile()
      await setUser(null)
    } catch (err) {
      console.log(err)
    }
  }

  const changeMainCompany = async () => {
    keyCompanyOffer.value = KeyCompanyOffer.secondOffer
    await setJobSearch(true)
  }

  const getCompanyOffer = async () => {
    try {
      const {
        data: { newOffer },
      } = await playersApi.playersOffer()
      offerCompanyInfo.value = newOffer
    } catch (err) {
      console.log(err)
    }
  }

  const playersBindOffer = async (info: object) => {
    try {
      const {
        data: { profile },
      } = await playersApi.playersBindOffer(info)
      setUser(profile)
    } catch (err) {
      console.log(err)
    }
  }

  const { ignoreUpdates } = watchTriggerable(
    clickerCount,
    async (v, _, onCleanup) => {
      disableScroll.on()
      let canceled = false
      onCleanup(() => (canceled = true))
      await new Promise((resolve) => setTimeout(resolve, 1000))

      if (canceled) return
      disableScroll.off()
      // Если идёт поиск работы анимация
      if (user.value?.isJobSearch) {
        jobsSearchAnimate(true)
      }
    },
  )

  return {
    isMainLoading,
    user,
    clickerCount,
    jobSearchProgressCount,
    taskInfo,
    resultTask,
    isStartTask,
    openOverlay,
    openTooltip,
    keyCompanyOffer,
    offerCompanyInfo,
    setUser,
    setJobSearch,
    setOnboard,
    setTask,
    setResultTask,
    endTask,
    resetTask,
    setClickerCount,
    resetClickerCount,
    setOpenOverlay,
    setOpenTooltip,
    getUserData,
    playersTap,
    nextTask,
    updateProfile,
    deleteProfile,
    changeMainCompany,
    getCompanyOffer,
    playersBindOffer,
  }
})
