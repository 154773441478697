import { axios, apiV1Url } from '@/api'

export const api = {
  getMission() {
    return axios.get(`${apiV1Url}players/missions/list`)
  },
  completeMission(missionId: string) {
    return axios.post(`${apiV1Url}players/missions/${missionId}/complete`)
  },
  rewardMission(missionId: string) {
    return axios.post(`${apiV1Url}players/missions/${missionId}/reward/collect`)
  },
}
