import { ref } from 'vue'

export default function usePopupToShow() {
  const popupToShow = ref<string | null>(null)
  const openPopup = (namePopup: string) => (popupToShow.value = namePopup)
  const hidePopup = () => (popupToShow.value = null)

  return {
    popupToShow,
    openPopup,
    hidePopup,
  }
}
