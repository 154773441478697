import { notify } from '@kyvg/vue3-notification'

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install: (app: any) => {
    function notifications(
      type?: string,
      idNotificationToClose?: number,
      options?: {
        title?: string
        message?: string
        iconName?: string
        subTitle?: string
        subIconName?: string
        btnTitle?: string
        btnEvent?: string
        duration?: number
      },
    ) {
      setTimeout(() => {
        return idNotificationToClose
          ? notify.close(idNotificationToClose)
          : notify({
              id: Date.now(),
              title: options?.title || '',
              text: options?.message || '',
              data: {
                iconName: options?.iconName || '',
                subTitle: options?.subTitle || '',
                subIconName: options?.subIconName || '',
                btnTitle: options?.btnTitle || '',
                btnEvent: options?.btnEvent || '',
              },
              type,
              group: 'notificationGroup',
              duration: options?.duration || 3000,
            })
      }, 0)
    }

    app.config.globalProperties.$alertNotification = notifications
  },
}
