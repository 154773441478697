export const TOOLTIP_LIST = {
  'start-click-person': 'start-click-person',
  'start-tsk-person': 'start-tsk-person',
  'start-company': 'start-company',
  'start-task': 'start-task',
  'swipe-task': 'swipe-task',
  // 'cancel-task': 'cancel-task',
  'play-task': 'play-task',
}

export const HOLE_OVERLAY = {
  'hole-start': 'hole-start',
  'hole-start-click': 'hole-start-click',
}
