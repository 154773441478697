export const LANGUAGE_LIST = {
  russian: {
    value: 'ru',
    name: 'Russian',
    desc: 'Русский',
  },
  english: {
    value: 'en',
    name: 'English',
    desc: 'English',
  },
}

export const FRIENDS_REWARD = 3000
