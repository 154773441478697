import { erf, sqrt } from 'mathjs'
import { TapParams } from '@/types/user/TapParams'

export const jobsSearchAnimate = (isRemoveAnimate?: boolean) => {
  const ntfSearchId = document?.getElementById('ntfSearch') as HTMLElement
  if (!ntfSearchId) return

  if (isRemoveAnimate) {
    ntfSearchId.classList.remove('is-active')
    return
  }

  ntfSearchId.classList.add('is-active')
}

export const jobsSearch = (clickerCount: number, tapParams: TapParams) => {
  // Параметры
  const mu = tapParams.params?.MU || 200
  const sigma = tapParams.params?.SIGMA || 20

  // Функция стандартного нормального распределения
  function standardNormalCDF(x: number) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (1 + erf(x / sqrt(2))) / 2
  }

  // // Функция для вычисления вероятности успеха на точно i-ом клике
  function getProbabilityExact(i: number, mu: number, sigma: number) {
    const z1 = (i - mu) / sigma
    const z2 = (i - 1 - mu) / sigma
    return standardNormalCDF(z1) - standardNormalCDF(z2)
  }

  const probability = getProbabilityExact(clickerCount, mu, sigma)

  return Math.floor(Number(probability.toFixed(4)) * 10000)
}
