import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useTelegramWebApp } from '@/services/useTelegramWebApp'
import { authUser } from '@/helpers/auth/auth-user'
import { isCheckPlatform } from '@/helpers/defaultUtils'
import defaultLayout from '@/layouts/defaultLayout.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter()
const { ready, platform, userInfo, startParam } = useTelegramWebApp()
const isDevelopment = process.env.NODE_ENV === 'development'

onMounted(async () => {
  ready()

  if (!isCheckPlatform(platform) && !isDevelopment) {
    await router.push({ name: 'error', params: { code: 403 } })
  }

  const telegramUserInfo = {
    id: userInfo?.id || 0,
    first_name: userInfo?.first_name || '',
    last_name: userInfo?.last_name || '',
    username: userInfo?.username || '',
    language_code: userInfo?.language_code || 'ru',
  }

  // Данные только для локалки
  const LOCAL_TG_USER_INFO = {
    id: 5454541,
    first_name: 'first_name',
    last_name: 'last_name',
    username: 'username',
    language_code: 'ru',
  }

  // Приглашение
  const inviteID = startParam?.split('profileId-')[1]

  // Если не пришла инфа от телеги, то перезагрузить страницу
  if (!telegramUserInfo?.id && !isDevelopment) {
    window.location.reload()
    return
  }

  const isUserAuth = await authUser(
    isDevelopment ? LOCAL_TG_USER_INFO : telegramUserInfo,
    inviteID,
  )

  if (!isUserAuth) {
    await router.push({ name: 'error', params: { code: 401 } })
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(defaultLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}
}

})